const config = {
  SERVICE: {
    GOOGLE: {
      name: 'google'
    },
    VOXYGEN: {
      name: 'voxygen'
    },
    ELEVENLABS: {
      name: 'elevenlabs'
    }
  }
}

export default config
