// Configs
import configWebSocket from '@/config/webSocket'

// Libs
import Bus from '@/Bus'
import { getToken } from '@/utils/auth'

// Dependencies
import Cookies from 'js-cookie'

let connectionTimeout = null
let socket = null
const eventMethods = {}

/**
 * Get WebSocket url
 * @param {Object} assistant
 * @param {String} locale
 * @returns {String}
 */
const getWebSocketUrl = (assistant, locale) => {
  const host = window.location.host
  // Use wss protocol (like https) for staging & production
  const protocol = window.location.hostname === 'localhost' ? 'ws' : 'wss'
  return `${protocol}://${host}/websocket/admin/${assistant.id}/${assistant.slug}/${locale}`
}

/**
 * Socket open event method
 */
const socketOpenEventMethod = () => {
  clearConnectionInterval()
  // update the connection status
  Bus.$emit('setWebSocketConnectionStatus', true)
  // Remove the adminToken from cookies
  Cookies.remove('adminToken')
}

/**
 * Socket on message event method
 * @param {Object} event
 */
const socketMessageEventMethod = event => {
  try {
    const data = JSON.parse(event.data)
    if (!data) {
      return
    }
    switch (data.type) {
      case configWebSocket.ADMIN_EVENT_TYPES.LIVE_CHAT.NB_ACTIVE_CONV_UPDATE:
        // Handle live chat notification
        Bus.$emit('handleLiveConversationNotification', data)
        break
      case configWebSocket.ADMIN_EVENT_TYPES.LIVE_CHAT.LIST_CONV_UPDATE:
        // Handle live chat conversations list updates
        Bus.$emit('handleInProgressConversationEvent', data)
        break
      case data.type === configWebSocket.ADMIN_EVENT_TYPES.LIVE_CHAT.ERROR_MESSAGE:
        Bus.$emit('showLiveConversationWebsocketErrorMessage', data)
        break
      case configWebSocket.ADMIN_EVENT_TYPES.LIVE_CHAT.LIVE_AGENTS_LIST:
        // Handle live chat agent list
        Bus.$emit('updateLiveAgentList', data.liveAgents)
        break
      case configWebSocket.ADMIN_EVENT_TYPES.ACTIVE_CONVERSATIONS.SINGLE_CONV_UPDATE:
        Bus.$emit('updateLiveConversation', data)
        break
      case configWebSocket.ADMIN_EVENT_TYPES.ACTIVE_CONVERSATIONS.LIST_UPDATE:
        Bus.$emit('updateLiveConversationInList', data)
        break
      default:
        break
    }

    // @TODO later : add a switch for new other event type
    // Example of new coming event : when a user ask to speak to a real human, a notification will be displayed in the sidebar
  } catch (err) {
    console.error(err)
  }
}

/**
 * Socket close event method
 * @param {Object} assistant
 * @param {String} locale
 */
const socketCloseEventMethod = (assistant, locale) => {
  clearConnectionInterval()

  // try to reconnect with the server
  connectionTimeout = setInterval(() => {
    initWebSocketConnection(assistant, locale)
  }, configWebSocket.RECONNECT_INTERVAL)
  // update the connection status
  Bus.$emit('setWebSocketConnectionStatus', false)
}

/**
 * Initialize WebSocket connection to the server
 * @param {Object} assistant
 * @param {String} locale
 */
const initWebSocketConnection = (assistant, locale) => {
  // WebSocket protocol is available in all modern web browsers, so no external library required
  if (typeof WebSocket !== 'function') {
    return
  }

  // Since Websocket does not support headers we need to set adminToken in cookies
  const token = getToken()
  Cookies.set('adminToken', token)
  socket = new WebSocket(getWebSocketUrl(assistant, locale))

  if (!eventMethods.openEventMethod) {
    eventMethods.openEventMethod = socketOpenEventMethod.bind()
  }
  socket.addEventListener('open', eventMethods.openEventMethod)

  if (!eventMethods.messageEventMethod) {
    eventMethods.messageEventMethod = socketMessageEventMethod.bind()
  }
  socket.addEventListener('message', eventMethods.messageEventMethod)

  if (!eventMethods.closeEventMethod) {
    eventMethods.closeEventMethod = socketCloseEventMethod.bind(null, assistant, locale)
  }
  socket.addEventListener('close', eventMethods.closeEventMethod)
}

/**
 * Send message from admin
 * @param {String} conversationId
 * @param {String} adminId
 * @param {Array<Object>} message
 */
const sendAdminMessage = (conversationId, adminId, message) => {
  socket.send(
    JSON.stringify({
      type: configWebSocket.ADMIN_EVENT_TYPES.LIVE_CHAT.ADMIN_MESSAGE,
      adminId,
      message,
      conversationId
    })
  )
}
/**
 * Destruct WebSocket connection from the server
 */
const destructWebSocketConnection = () => {
  clearConnectionInterval()
  if (socket) {
    socket.removeEventListener('open', eventMethods.openEventMethod)
    socket.removeEventListener('message', eventMethods.messageEventMethod)
    socket.removeEventListener('close', eventMethods.closeEventMethod)
    socket.close()
    socket = null
  }
  // update the connection status
  Bus.$emit('setWebSocketConnectionStatus', false)
}

/**
 * Clear the connectionTimeout
 */
const clearConnectionInterval = () => {
  if (connectionTimeout) {
    clearInterval(connectionTimeout)
    connectionTimeout = null
  }
}

/**
 * Join a websocket room
 * @param {String} roomId
 */
const joinRoom = roomId => {
  try {
    socket.send(
      JSON.stringify({
        type: configWebSocket.ADMIN_EVENT_TYPES.JOIN_ROOM,
        roomId
      })
    )
  } catch (error) {
    console.error('joinRoom error', error)
    return
  }
}

/**
 * Leave a websocket room
 * @param {String} roomId
 */
const leaveRoom = roomId => {
  try {
    socket.send(
      JSON.stringify({
        type: configWebSocket.ADMIN_EVENT_TYPES.LEAVE_ROOM,
        roomId
      })
    )
  } catch (error) {
    console.error('leaveRoom error', error)
    return
  }
}

/**
 * Get a room id
 * @param {String} assistantId
 * @param {String} locale
 * @param {String|null} [suffix]
 * @returns {String}
 */
const getRoomId = (assistantId, locale, suffix = null) => {
  return [assistantId, locale, suffix].filter(value => value).join(configWebSocket.ROOM_ID_SEPARATOR)
}

export { initWebSocketConnection, sendAdminMessage, destructWebSocketConnection, joinRoom, leaveRoom, getRoomId }
