// Configs
import constants from '@/config/constants'
import configAction from '@/config/action'

// Dependencies
import escapeStringRegexp from 'escape-string-regexp'
import sanitizeHTML from 'sanitize-html'
import parsePhoneNumber, { formatNumber } from 'libphonenumber-js'

// Utils
import Cursor from '@/utils/cursor'
import { sortArrayOfObjectsByProperty } from '@/utils/arrayManipulation'

/**
 * Capitalize the first character of a string
 *
 * @param {String} string - String to capitalize
 * @returns {String} - String capitalized
 */
export function capitalize(string) {
  return string && string.length > 1 && typeof string === 'string'
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : string
}

/**
 * Capitalize the first character of every word in the string
 * @param {String} str - String to change case
 * @returns {String} - changed string
 */
export function capitalizeFirstLetters(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => capitalize(word))
    .join(' ')
}

/**
 * Truncate text to the length wanted
 * @param {String} text - String to truncate
 * @param {Number} length - Length wanted
 * @param {String} endString - End string after truncated text
 * @returns {String} - Truncated string or original one
 */
export function truncateText(text, length, endString = '') {
  if (text && text.length > length) {
    return `${text.substring(0, length)}${endString}`
  }
  return text
}

/**
 * Generate a slug from an array of strings
 *
 * @param {Array<String>} strings - Array of strings
 * @returns {String} - slug
 */
export function generateSlug(strings) {
  const slug = strings.reduce((slug, string) => {
    return slug.concat((slug.length ? '_' : '') + removeNonLettersChars(normalizeLowerCase(string)))
  }, '')

  return slug.replace(/^_+|_+$/g, '')
}

/**
 * Remove all chars that are not letters from a string
 *
 * @param {String} string - the string to manipulate
 * @returns {String} filtered string
 */
export function removeNonLettersChars(string) {
  return string.replace(/[^A-z_]/gi, '')
}

/**
 * Escape html chars
 *
 * @param {String} string - String to escape
 * @returns {String}
 */
export function escapeHtml(string) {
  const entityMap = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
    '/': '&#x2F;'
  }

  return string.replace(/[&<>"'\/]/g, match => {
    return entityMap[match]
  })
}

/**
 * Trim white spaces, replace other by underscore, force lower case, replace diacritics (accents)
 * @param {String} string - the string to manipulate
 * @param {Boolean} removeSpace
 * @return {String} the transformed string
 */
export function normalizeLowerCase(string, removeSpace = true) {
  string = ('' + string).trim() // Security cast + remove start and end whitespaces
  string = string
    .toLowerCase() // force lowercase
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // replace special characters

  if (removeSpace) {
    string = string.replace(/\s|-/g, '_') // replace other whitespaces and dashes by _
  }

  return string
}

/**
 * Replace markdown with HTML
 * See website-chat utils for more info about the regexes
 *
 * @param {String} message
 * @return {String} the transformed string
 */
export function replaceMarkdown(message) {
  if (!message) {
    return ''
  }
  // Remove HTML tags
  message = sanitizeHTML(message, { allowedTags: [] })

  // Handle markdown links ([description](link))
  message = message.replace(configAction.MARKDOWN_LINK_REGEX, '<a href="$2" target="_blank">$1</a>')

  // Handle solo links
  message = message.replace(/(https?:\/\/[^\s]+)/g, url => {
    if (url.includes('"')) {
      return url
    } else {
      return `<a href="${url}" target="_blank">${url}</a>`
    }
  })

  // Handle markdown bold (double ** and __)
  message = message.replace(/(\*\*|__)(.*?)\1/g, '<strong>$2</strong>')

  // Handle markdown italic (single _)
  message = message.replace(/(^|\s)(?:\_)((?!_).*?)(?:\_)/g, '$1<em>$2</em>')

  // Handle markdown italic (single *)
  message = message.replace(/(\*)(.*?)\1/g, '<em>$2</em>')

  // Handle markdown lists, it creates an ul tag for every item, extra tags will get removed after
  // Unordered lists created from lines beginning with -
  message = message.replace(/(?:^|\n)-(.*)/g, (text, item) => {
    return `\n<ul><li>${item.trim()}</li></ul>`
  })

  // Remove extra ul tags created above
  message = message.replace(/<\/ul>\s?<ul>/g, '')

  // Replace \n with br tags
  message = message.replace(/\n/g, '<br>')

  return message
}

/**
 * Generate consistent vibrant color from a string
 * - From https://github.com/brandoncorbin/string_to_color
 * - Cache colors to not have to recalculate them everytime
 *
 * @param {String} string
 * @param {Number} luminosity
 * @returns {String}
 */
const colorCache = new Map()
export function stringToColor(string, luminosity = 50) {
  // Check if the color for this string is cached
  const cachedColor = colorCache.get(string + luminosity)
  if (cachedColor) {
    return cachedColor
  }

  // Generate a Hash for the String
  const hash = word => {
    let hash = 0
    for (let index = 0; index < word.length; index++) {
      hash = word.charCodeAt(index) + ((hash << 5) - hash)
    }
    return hash
  }

  // Change the darkness or lightness
  const shade = (color, luminosity) => {
    const num = parseInt(color, 16)
    const amt = Math.round(2.55 * luminosity)
    const red = (num >> 16) + amt
    const green = ((num >> 8) & 0x00ff) + amt
    const blue = (num & 0x0000ff) + amt
    return (
      0x1000000 +
      (red < 255 ? (red < 1 ? 0 : red) : 255) * 0x10000 +
      (green < 255 ? (green < 1 ? 0 : green) : 255) * 0x100 +
      (blue < 255 ? (blue < 1 ? 0 : blue) : 255)
    )
      .toString(16)
      .slice(1)
  }

  // Convert integer to an RGBA
  const int_to_rgba = integer => {
    const color =
      ((integer >> 24) & 0xff).toString(16) +
      ((integer >> 16) & 0xff).toString(16) +
      ((integer >> 8) & 0xff).toString(16) +
      (integer & 0xff).toString(16)
    return color
  }

  const color = shade(int_to_rgba(hash(string)), luminosity)

  // Cache the color
  colorCache.set(string + luminosity, color)

  return color
}

/**
 * Get contract color code based on given bg color code
 * @param {String} colorCode
 * @returns {String} colorCode
 */
export function getContrastColor(colorCode) {
  /**
   * References
   * https://stackoverflow.com/a/11868159
   * https://24ways.org/2010/calculating-color-contrast
   */

  const DARK_COLOR = 'black'
  const LIGHT_COLOR = 'white'

  colorCode = colorCode.replace('#', '')

  const red = parseInt(colorCode.substr(0, 2), 16)
  const green = parseInt(colorCode.substr(2, 2), 16)
  const blue = parseInt(colorCode.substr(4, 2), 16)

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000

  return brightness > 125 ? DARK_COLOR : LIGHT_COLOR
}
/**
 * Generate hash for the string
 * @param {String} slug
 * @returns {String} hashed string
 */
export const hashCode = slug => {
  let hash = 0
  let idx

  if (slug.length === 0) return hash.toString(36)
  for (idx = 0; idx < slug.length; idx++) {
    const chr = slug.charCodeAt(idx)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }

  return hash.toString(36)
}

/**
 * Convert camel case to snake case
 * E.g. staticAnswers => static_answers
 * @param {String} string
 * @returns {String}
 */
export const convertFromCamelToSnake = string => {
  return string.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

/**
 * Convert snake case to camel case
 * E.g. static_answers => staticAnswers
 * @param {String} string
 * @returns {String}
 */
export const convertFromSnakeToCamel = string => {
  return string.replace(/([-_][a-z])/g, match => match.toUpperCase().replace('_', ''))
}

/**
 * Convert kebab case to snake case
 * E.g. google-chat => google_chat
 * @param {String} string
 * @returns {String}
 */
export const convertFromKebabToSnake = string => {
  return string.replace(/-/g, '_')
}

/**
 * Convert snake case to kebab case
 * E.g. google_chat => google-chat
 * @param {String} string
 * @returns {String}
 */
export const convertFromSnakeToKebab = string => {
  return string.replace(/_/g, '-')
}

/**
 * Convert camel case to kebab case
 * E.g. googleChat => google-chat
 * @param {String} string
 * @returns {String}
 */
export const convertFromCamelToKebab = string => {
  return string.replace(/[A-Z]+(?![a-z])|[A-Z]/g, (str, ofs) => (ofs ? '-' : '') + str.toLowerCase())
}

/**
 * Convert Capitalized snake case to Camel case
 * E.g. ARRAY_OF_STRINGS => arrayOfStrings
 * @param {String} string
 * @returns {String}
 */
export const convertConstantCaseToCamel = string => {
  return string.toLowerCase().replace(/_[a-z]{1}/g, letter => `${letter.toUpperCase().replace(/_/g, '')}`)
}

/**
 * Convert a string with spaces to snake case
 * E.g. Not Found => not_found
 * @param {String} string
 * @returns {String}
 */
export const convertStringToSnake = string => {
  return string.toLowerCase().replace(/ /g, '_')
}

/**
 * Get Regexp safe string
 * @param {String} string
 * @returns {String} - regex safe string
 */
export const escapeRegexString = string => {
  return escapeStringRegexp(string)
}

/**
 * Fallback method to copy string to clip board using the element's ID
 * @param {String} elementId
 * @returns {Promise<Object>}
 */
const fallbackCopyTextToClipboard = elementId => {
  return new Promise(resolve => {
    const target = document.getElementById(elementId)
    target.focus()
    target.select()

    try {
      const successful = document.execCommand('copy')

      if (successful) {
        resolve()
      }
    } catch (err) {
      console.error('Error while copy to clipboard', err)
    }
  })
}

/**
 * Copy given string to clipboard
 * @param (String) strToCopy
 * @param (String) elementId
 * @returns {Promise<Object>}
 */
export const copyToClipBoard = (strToCopy, elementId) => {
  return new Promise(resolve => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(elementId).then(() => {
        resolve()
      })
    }
    navigator.clipboard.writeText(strToCopy).then(() => {
      resolve()
    })
  })
}

/**
 * Checks the given searched string is valid for the searched slug
 * @param {String} newVal
 * @param {String} oldVal
 * @returns {Boolean}
 */
export const isSearchedStringValid = (newVal, oldVal) => {
  return (
    newVal.length >= constants.SEARCH_MIN_LENGTH ||
    (newVal.length < constants.SEARCH_MIN_LENGTH && oldVal.length === constants.SEARCH_MIN_LENGTH) ||
    !newVal.length
  )
}

/**
 * Remove Text selection in the window
 */
export const clearTextSelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty()
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges()
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty()
  }
}

/**
 * Get Formatted File size from given bytes
 * @param {Number} bytes
 * @return {String} formatted file size
 */
export const formatFileSize = bytes => {
  const threshold = 1024
  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`
  }

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let unitIndex = -1
  do {
    bytes /= threshold
    ++unitIndex
  } while (Math.abs(bytes) >= threshold && unitIndex < units.length - 1)

  return `${bytes.toFixed(1)} ${units[unitIndex]}`
}

/**
 * Returns a new string with all matches of line break replaced by a replacement string
 * @param {String} string - the original string
 * @param {String} newSubstr - the string that replace the lines breaks
 * @returns {String} -string with all matches of line break replaced by newSubstr
 */
export const removeLineBreaks = (string, newSubstr = '') => {
  if (typeof string !== 'string') {
    return ''
  }

  /**
   * \r matches a carriage return (ASCII 13)
   * \n matches a line-feed (newline) character (ASCII 10)
   * \t matches a tab character (ASCII 9)
   * {1,} Quantifier — Matches between one and unlimited times, as many times as possible, giving back as needed (greedy)
   * {1,} used to replace multiple continuous occurrence of new line with the replace string
   */
  return string.replace(/(\r\n|\n|\r|\t){1,}/gm, newSubstr).trim()
}

/**
 * Generate a random string of defined length
 * @param {Number} length
 * @returns {String}
 */
export const generateRandomString = length => {
  let randomString = ''
  for (let index = 0; index < length; index++) {
    randomString += Math.random().toString(36).substring(2, 3)
  }
  return randomString
}

/**
 * Removes in between spaces from the provided string
 * @param {String} string
 * @returns {String}
 */
export const removeInBetweenSpaces = string => {
  /**
   * \u2060 is the hex code for the zero width space ASCII code &#8288;
   * \s{2,} matches any whitespace character (equal to [\r\n\t\f\v ])
   * {2,} Quantifier — Matches between 2 and unlimited times, as many times as possible, giving back as needed (greedy)
   * replace &nbsp; with space
   **/
  return string
    .replace(/\u2060/g, '')
    .replace(/\s{2,}/g, ' ')
    .replace(/&nbsp;/gi, ' ')
}

/**
 * Return string parameter with emoji at current cursor position
 * @param {DOMElement} target - HTMLElement which hold the string text
 * @param {String} emoji - emoji ascii code
 * @param {String} string - text to be editier
 * @returns {String} string parameter with emoji inserted at current cursor position
 */
export function insertEmojiIntoTextAtCursorPosition(target, emoji, string) {
  // Get the cursor position
  const cursor = new Cursor(target)
  const position = cursor.getPosition()

  // Insert the selected emoji into the the string
  return string.substring(0, position) + emoji + string.substring(position, string.length)
}

/**
 * Format locales to get both their value and their localized name
 * And sort it by localized name rather than value so they will be displayed alphabetically
 * @param {Array} locales
 * @param {Object} $i18n
 * @returns {Array} formatted and sorted locales
 */
export function formatLocales(locales, $i18n) {
  const formattedLocales = locales.map(locale => {
    return {
      value: locale,
      name: $i18n.t(`locales.${locale}`)
    }
  })
  return sortArrayOfObjectsByProperty(formattedLocales, 'name')
}

/**
 * Check if at least one element of the array has an object property that is greater than limit
 * @param {Array<Object>} objectsArray - array of objects
 * @param {Number} limit - number to compare the object property
 * @param {String} propertyName - name of the property
 * @return {Boolean}
 */
export function hasLongerText(objectsArray, limit, propertyName = 'text') {
  return objectsArray.some(object => {
    return object[propertyName] && [...object[propertyName]].length > limit
  })
}

/**
 * Count the number of words in the string
 * @param {String} str
 * @returns {Number}
 */
export function countWords(str) {
  if (!str) {
    return 0
  }

  // The regex is used to find the number of word in chinese sentence
  /**
   * In Chinese in this "香港人" sentence there are three words
   * So to count the number of words along with S+(space) this regex [\u00ff-\uffff] is used
   * Ref: https://stackoverflow.com/questions/20396456/how-to-do-word-counts-for-a-mixture-of-english-and-chinese-in-javascript
   */
  const matches = str.match(/[\u00ff-\uffff]|\S+/g)
  return matches ? matches.length : 0
}

/**
 * Return a stringified trimmed value
 * @param {String|Number} value
 * @param {String}
 */
export function getStringifiedTrimmedValue(value) {
  if (!value) {
    return ''
  }

  if (typeof value === 'string') {
    return value.trim()
  }

  return value.toString().trim()
}
/**
 * Replace the string at the given index with the given replacement string.
 * @param sourceString - The string you want to replace a character in.
 * @param index - The index of the string you want to replace
 * @param replacement - The string to replace the character at the index with.
 * @returns a new string that is the sourceString with the replacement string inserted at the index.
 */

const replaceStringAt = (sourceString, index, replacement) => {
  return sourceString.substring(0, index) + replacement + sourceString.substring(index + replacement.length)
}

/**
 * Format a number to international format and then
 * Replace phone number characters between the first nbLastCharactersToKeep numbers after the country code and the last nbLastCharactersToKeep numbers by a replacementCharacter
 *  [countryCode][N1][N2][N3][N4][N5][N6][N7]=> [countryCode][N1] ** [N4][N5] [N6][N7]
 * @param {String} phoneNumber [countryCode][N1][N2][N3][N4][N5][N6][N7]
 * @param {Number} [nbLastCharactersToKeep=4] number of the last characters to keep
 * @param {Number} [nbFirstCharactersToKeep=1] number of the first characters to keep after the country code
 * @param {String} [replacementCharacter='*'] value which replaced masked characters
 * @returns {String} [countryCode][N1] ** [N4][N5] [N6][N7]
 */
export function maskUserPhoneNumber(
  phoneNumber,
  nbLastCharactersToKeep = 4,
  nbFirstCharactersToKeep = 1,
  replacementCharacter = '*'
) {
  const DEFAULT_COUNTRY_CODE = 'FR'
  phoneNumber = phoneNumber.trim()
  const phoneNumberObj = parsePhoneNumber(phoneNumber, DEFAULT_COUNTRY_CODE)

  if (!phoneNumberObj) {
    return phoneNumber
  }

  const internationalPart = `+${phoneNumberObj.countryCallingCode}`

  const nbCharactersToReplace = phoneNumberObj.nationalNumber.length - nbLastCharactersToKeep - nbFirstCharactersToKeep
  if (nbCharactersToReplace <= 0) {
    return internationalPart + phoneNumberObj.nationalNumber
  }

  // Remove the country code from the formatted number
  const formattedNationalNumber = getFormattedNationalPart(phoneNumberObj, internationalPart)

  // Mask the required number other than space character

  return (
    internationalPart +
    replaceCharacters(formattedNationalNumber, nbCharactersToReplace, nbFirstCharactersToKeep, replacementCharacter)
  )
}

/**
 * It replaces a number of characters in a string with a given character, starting from the end of the string, and ignoring
 * a given list of characters
 * @param {String} toReplace - the string to replace
 * @param {Number} nbCharactersToReplace - the number of characters to replace
 * @param {Number} nbFirstCharactersToKeep - the number of characters to keep at the beginning of the string
 * @param {String} replacementCharacter - the character to replace the masked characters with
 * @param {String[]} [ignoredCharacters] - an array of characters that should not be replaced
 * @returns A function that takes in 5 parameters and returns a string.
 */
const replaceCharacters = (
  toReplace,
  nbCharactersToReplace,
  nbFirstCharactersToKeep,
  replacementCharacter,
  ignoredCharacters = [' ']
) => {
  for (let index = 0; index < toReplace.length; index++) {
    if (index < nbFirstCharactersToKeep || ignoredCharacters.includes(toReplace.charAt(index))) {
      continue
    }

    if (nbCharactersToReplace <= 0) {
      break
    }

    toReplace = replaceStringAt(toReplace, index, replacementCharacter)
    nbCharactersToReplace--
  }
  return toReplace
}

/**
 * Takes a phone number object and an international part, and returns a formatted phone number without the international part
 * @param {Object} phoneNumberObj - The phone number object returned by the libphonenumber library.
 * @param {String} internationalPart - The international part of the phone number.
 * @returns {String} The formatted number with the international part removed.
 */
const getFormattedNationalPart = (phoneNumberObj, internationalPart) => {
  const formattedNumber = formatNumber(internationalPart + phoneNumberObj.nationalNumber, 'International')
  return formattedNumber.replace(internationalPart, '').trim()
}

/**
 * Encode HTML tag characters <, > in a string with their unicode value
 * @param {String} text
 * @returns {String}
 */
export function encodeHTMLTags(text) {
  return text.replace(/[<>]/g, char => {
    return '&#' + char.charCodeAt(0) + ';'
  })
}

/**
 * Check if a string | number is of number type
 * @param {String|Number} value
 * @returns {Boolean}
 */
export function isNumber(value) {
  return !Number.isNaN(Number(value))
}

/**
 * Format the input to number or return null
 * @param {String|Number} input
 * @returns {Number| null}
 */
export function formatNumberInputToNumberOrNull(input) {
  if (input === '' || input === null) {
    return null
  }
  // Use the Number constructor to attempt to convert the input to a number
  const number = Number(input)
  // Check if the conversion is successful and the result is a finite number
  return !isNaN(number) && isFinite(number) ? number : null
}

/**
 * Check whether the provided regexString is valid or not
 * @param {String} regexString
 * @returns {Boolean}
 */
export function isValidRegex(regexString) {
  try {
    new RegExp(regexString)
    return true
  } catch (_err) {
    return false
  }
}
