'use strict'

import emojiRegex from 'emoji-regex'
import validUrl from 'valid-url'

// API
import ttsApi from '@/api/tts'
// Config
import constants from '@/config/constants'
import voiceConfig from '@/config/voice'
let AudioPlayer = false

/**
 * Ensure that the audioPlayer is properly initialized.
 *  - We need it to be initialized by an user event, so we have
 *    the browser auth to play a sound when we want
 *  - More infos : https://github.com/scottschiller/SoundManager2/issues/178
 *
 * @returns {Boolean}
 */
const ensureInit = () => {
  if (AudioPlayer) {
    return false
  }

  AudioPlayer = new Audio()
  AudioPlayer.src = '' // Playing, even an inexistant file, unlock the auth

  // As the src is empty, it will always throw an error
  AudioPlayer.play().catch(error => {
    // The play failed because the src is empty or because it was stopped early, but we have the auth
    if ([9, 20].includes(error.code)) {
      return true
    }

    console.info('AudioPlayer init failed: ' + error.message)
    AudioPlayer = false
    return false
  })
}

/**
 * Check if an string is a valid mp3 url
 *
 * @param {String} mp3Url - Url of the mp3 file
 * @returns {Boolean}
 */
const isMp3Url = mp3Url => {
  return validUrl.isWebUri(mp3Url) && mp3Url.endsWith('.mp3')
}

/**
 * Start the playback of a mp3 file
 *
 * @param {String} mp3Url - Url of the mp3 file
 * @returns {Boolean}
 */
const playMp3 = mp3Url => {
  if (!AudioPlayer || !isMp3Url(mp3Url)) {
    return false
  }

  stop()
  AudioPlayer.src = mp3Url
  AudioPlayer.load()
  AudioPlayer.play().catch(() => {
    return false
  })

  return true
}

/**
 * Stop the playback of a mp3
 */
const stop = () => {
  if (!AudioPlayer) {
    return false
  }

  AudioPlayer.pause()
  AudioPlayer.currentTime = 0.0

  return true
}

/**
 * Remove regex from a string
 *
 * @param {String} string
 * @param {Regex} regex
 * @param {Array} exceptions - Characters we want to keep
 * @returns {String}
 */
const removeRegex = (string, regex, exceptions = []) => {
  let match = regex.exec(string)
  while (match) {
    if (!exceptions.includes(match[0])) {
      string = string.replace(match[0], '')
    }
    match = regex.exec(string)
  }
  return string
}

const removeEmoji = string => {
  string = removeRegex(string, emojiRegex())

  // Remove textual representation of the emoji but keep € symbol
  const regex =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
  return removeRegex(string, regex, ['€'])
}

const generateTTS = (text, voice, locale, service = voiceConfig.SERVICE.GOOGLE.name) => {
  if (voice === '') {
    voice = `${locale.replace('_', '-')}-${constants.DEFAULT_VOICE}`
  }

  const ssml = `<speak>${text}</speak>`

  ttsApi.generateTTS(ssml, voice, locale, service).then(data => {
    if (data && data.mp3Url) {
      playMp3(data.mp3Url)
    }
  })
}
/**
 * Format voices list by adding label, mp3 url and locale if necessary to a voice
 * @param {Array} voices list of all voices for a locale
 * @param {Array} audio list of audio of the voices
 * @param {Object} i18n localization for voices labels
 * @param {String} service google or voxygen (voiceConfig.SERVICE)
 * @param {String} locale locale of the voice
 * @returns {Array} formatted voices
 */
const formatVoicesListItem = (voices, audio, i18n, service = voiceConfig.SERVICE.GOOGLE.name, locale = null) => {
  voices.forEach((voice, index) => {
    voice.label = service + ' ' + i18n.t(`gender.${voice.gender.toLowerCase()}`) + ' ' + (index + 1)
    voice.mp3Url = audio[voice.name]
    voice.voiceService = service

    if (locale) {
      voice.locale = locale
    }
  })
  return voices
}

/**
 * get voices list for a locale
 * @param {String} locale locale of the voice
 * @param {Object} $i18n localization for voices labels
 * @returns {Promise<Array>} formatted voices
 */
const getVoices = async (locale, $i18n) => {
  const voices = []
  const services = Object.keys(voiceConfig.SERVICE)
  for (const service of services) {
    const serviceName = voiceConfig.SERVICE[service].name
    const response = await ttsApi.getVoices(locale, constants.VOICES_TEST_PHRASES[locale], serviceName)
    if (response?.voices && response?.audio) {
      const femaleVoices = response.voices.filter(voice => voice.gender === 'FEMALE')
      const maleVoices = response.voices.filter(voice => voice.gender === 'MALE')
      voices.push(
        ...formatVoicesListItem(femaleVoices, response.audio, $i18n, serviceName),
        ...formatVoicesListItem(maleVoices, response.audio, $i18n, serviceName)
      )
    }
  }

  return voices
}

export default {
  ensureInit,
  playMp3,
  stop,
  removeEmoji,
  generateTTS,
  getVoices
}
